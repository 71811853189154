import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.less';
import Clause from './clause';
import Download from './download';

export default class App extends Component {
  render() {
    return <Switch>
      <Route path='/download/app' exact component={Download} />
      <Route path='/clause/:type' exact component={Clause} />
    </Switch>
  }
}