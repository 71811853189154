import { Avatar, Badge, Space } from "antd";
import React, { useEffect, useState } from "react";
import { DownloadBox, DownloadButtonStyle, DownloadImage, MaskBoxStyle, MaskImage, MaskImageStyle } from "./styles";
import Request from "./tool";

const Download = (props) => {
  const [width] = useState(window.innerWidth);
  const [height] = useState(window.innerHeight);
  const [is_show, setIsShow] = useState(false);
  const [android, setAndroid] = useState("https://img.shahaizhi.com/%E4%B8%80%E6%96%B9%E6%B0%B4%E5%9C%9F%E5%95%86%E5%9F%8E_V1.0.117_10117_jiagu_sign.apk");
  const [ios, setIos] = useState("");

  useEffect(() => {
    document.title = "水到渠成APP下载";
    init_download();
  }, [props]);

  const init_download = () => {
    Request({
      url: "version/getDownloadUrl",
      method: "GET",
    }).then((res) => {
      setAndroid(res.android);
      setIos(res.ios);
    });
  };

  const open_mask = () => {
    let ckeck_is_wx = isWeiXin();
    if (ckeck_is_wx) {
      setIsShow(true);
    } else {
      let platform = getPlatform();
      if (platform === "android") {
        //安卓下载链接
        window.location.href = android;
      } else {
        //ios下载链接
        window.location.href = ios;
      }
    }
  };
  const close_mask = () => {
    setIsShow(false);
  };

  const isWeiXin = () => {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) === "micromessenger") {
      return true; // 是微信端
    } else {
      return false;
    }
  };

  const getPlatform = () => {
    var ua = window.navigator.userAgent.toLowerCase();
    let platform = ua.indexOf("android") > -1 || ua.indexOf("adr") > -1;
    if (platform) {
      return "android";
    } else {
      return "ios";
    }
  };

  return (
    <>
      <DownloadBox h={height} w={width}>
        <>
          <DownloadImage w={(110 / 375) * width} h={(169.5 / 375) * width} t={(70 / 375) * width}>
            <img src="image/download_logo.png" alt="" />
          </DownloadImage>
          <p style={{ fontSize: 18, textAlign: "center", fontWeight: "bold", color: "#FF5E3C", marginTop: (130 / 667) * height }}>
            <span>鲁花岩溶山泉水</span>
          </p>
          <p style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
            <span>源自山东莱阳白垩纪国家地质公园</span>
          </p>
          <p style={{ fontSize: 18, fontWeight: "bold", color: "#FF5E3C", textAlign: "center" }}>
            {/* <span>赶紧预约上门送桶装水吧！</span> */}
            <span>真正天然山泉水</span>
          </p>
          <DownloadButtonStyle w={(221.5 / 375) * width} h={(42 / 667) * height} t={(45 / 667) * height} onClick={open_mask}>
            <img src="https://image.sdqc.club/download/%E7%BB%84%207%402x.png" width={"100%"} height={"100%"} alt="" />
          </DownloadButtonStyle>
        </>
      </DownloadBox>
      {is_show ? (
        <>
          <MaskBoxStyle h={height} w={width} onClick={close_mask}>
            <MaskImageStyle w={width} h={(211.5 / 667) * height}>
              <MaskImage w={(150 / 375) * width} h={(170.5 / 667) * height} t={(20 / 667) * height} m_l={(195 / 375) * width}>
                <img src="https://image.sdqc.club/download/%E7%BB%84%201%402x.png" alt="" />
              </MaskImage>
              {/* <MaskCloseButtonStyle w={(105 / 375) * width} h={(30 / 667) * height} t={(56 / 667) * height}>
                <span>我知道了</span>
              </MaskCloseButtonStyle> */}
              <p style={{ fontSize: 18, fontWeight: "bold", marginTop: (25 / 667) * height, marginLeft: (50 / 375) * width, color: "white" }}>
                <span>使用浏览器打开</span>
              </p>
              <p style={{ fontSize: 18, fontWeight: "bold", marginTop: (10 / 667) * height, marginLeft: (50 / 375) * width, color: "white" }}>
                <Space size={12}>
                  <Badge count={1} />
                  <span>点击右上角&nbsp;···&nbsp;按钮</span>
                </Space>
              </p>
              <p style={{ fontSize: 18, fontWeight: "bold", marginTop: (10 / 667) * height, marginLeft: (50 / 375) * width, color: "white" }}>
                <Space size={12}>
                  <Badge count={2} />
                  <span>
                    选择&nbsp;
                    <Avatar src={"https://image.sdqc.club/download/%E6%B5%8F%E8%A7%88%E5%99%A8%402x.png"} size={16} />
                    &nbsp;在浏览器打开
                  </span>
                </Space>
              </p>
              <p style={{ fontSize: 18, fontWeight: "bold", marginTop: (10 / 667) * height, marginLeft: (50 / 375) * width, color: "white" }}>
                <Space size={12}>
                  <Badge count={3} />
                  <span>打开点击下载APP即可</span>
                </Space>
              </p>
            </MaskImageStyle>
          </MaskBoxStyle>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Download;
